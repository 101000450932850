import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';
import { useListingRedirect } from '~hooks/useListingRedirect';

import Breadcrumb from '~components/breadcrumbs';
import Hero from '~components/hero';

const PrivacyPolicy = () => {
  const intl = useIntl();

  useLayout({ displayTopCTABar: false, displaySignIn: true });
  const listingRedirect = useListingRedirect();
  useSeo(
    intl.formatMessage({
      id: 'containers.privacy-policy.seo.title',
    })
  );

  return (
    <>
      <section className="c-subheader">
        <div className="l-container">
          <Breadcrumb.Wrapper>
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to={listingRedirect} index={1}>
                {intl.formatMessage({
                  id: 'breadcrumbs.home',
                })}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
            <Breadcrumb.Crumb>
              <FormattedMessage id="routes.privacy-policy">
                {(msg) => (
                  <Breadcrumb.CrumbLink to={`/${msg}`} index={2}>
                    {intl.formatMessage({
                      id: 'breadcrumbs.privacy-policy',
                    })}
                  </Breadcrumb.CrumbLink>
                )}
              </FormattedMessage>
            </Breadcrumb.Crumb>
          </Breadcrumb.Wrapper>
        </div>
      </section>
      <section className="c-page l-container">
        <Hero.Wrapper>
          <Hero.Title>
            {intl.formatMessage({
              id: 'containers.privacy-policy.headline',
            })}
          </Hero.Title>
          <Hero.Intro>
            {intl.formatMessage({
              id: 'containers.privacy-policy.introduction',
            })}
          </Hero.Intro>
        </Hero.Wrapper>

        <div className="c-faq l-page-content">
          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.first.title',
              })}
            </div>
            <div className="c-faq__response">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.first.content',
              })}
            </div>
          </div>

          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.second.title',
              })}
            </div>
            <div className="c-faq__response">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.second.content',
              })}
            </div>
          </div>
          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.third.title',
              })}
            </div>
            <div className="c-faq__response">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.third.content',
              })}
            </div>
          </div>
          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.fourth.title',
              })}
            </div>
            <div className="c-faq__response">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.fourth.content',
              })}
            </div>
          </div>
          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.fifth.title',
              })}
            </div>
            <div className="c-faq__response">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.fifth.content',
              })}
            </div>
          </div>
          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.sixth.title',
              })}
            </div>
            <div className="c-faq__response">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.sixth.content',
              })}
            </div>
          </div>
          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.seventh.title',
              })}
            </div>
            <div className="c-faq__response">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.seventh.content',
              })}
            </div>
          </div>

          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.delete-account.title',
              })}
            </div>
            <div className="c-faq__response">
              {intl.formatMessage(
                {
                  id: 'containers.privacy-policy.sections.delete-account.content',
                },
                {
                  profile: (
                    <Link to="/account" className="c-page__link">
                      {intl.formatMessage({
                        id: 'containers.privacy-policy.sections.delete-account.profile',
                      })}
                    </Link>
                  ),
                  contact: (
                    <FormattedMessage id="routes.contact">
                      {(msg) => (
                        <Link to={`/${msg}`} className="c-page__link">
                          {intl.formatMessage({
                            id: 'containers.privacy-policy.sections.delete-account.contact',
                          })}
                        </Link>
                      )}
                    </FormattedMessage>
                  ),
                  email: (
                    <a
                      href={`mailto:${intl.formatMessage({
                        id: 'containers.privacy-policy.sections.delete-account.email',
                      })}`}
                      className="c-page__link"
                    >
                      {intl.formatMessage({
                        id: 'containers.privacy-policy.sections.delete-account.email',
                      })}
                    </a>
                  ),
                }
              )}
            </div>
          </div>

          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.privacy-policy.sections.eighth.title',
              })}
            </div>
            <div className="c-faq__response">
              {intl.formatMessage(
                {
                  id: 'containers.privacy-policy.sections.eighth.content',
                },
                {
                  contact: (
                    <FormattedMessage id="routes.contact">
                      {(msg) => (
                        <Link to={`/${msg}`} className="c-page__link">
                          {intl.formatMessage({
                            id: 'containers.privacy-policy.sections.eighth.contact',
                          })}
                        </Link>
                      )}
                    </FormattedMessage>
                  ),
                }
              )}
            </div>
          </div>
          <div className="l-center">
            <Link to={listingRedirect} className="c-btn">
              {intl.formatMessage({
                id: 'containers.privacy-policy.back-to-homepage',
              })}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
